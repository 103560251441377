<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">-->
  <div v-if="!isMobile()">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
  <div v-else>
    <HelloWorldMobile msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import HelloWorldMobile from './components/HelloWorldMobile.vue'
//import Router from 'vue-router';

export default {
  name: 'App',
  components: {
    HelloWorld,
    HelloWorldMobile
  },
  created () {
    document.title = "Il matrimonio di Emanuele e Viola";
  },
  methods: {
      isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
</style>
