<template>
  <div class="container">
    <div class="background"></div>
    <div class="panel">
      <div class="profileSection">
        <div class="profileLeft">
          <div class="profilePic">
            <img src="@/assets/pixelwedding.png" alt="Avatar">
          </div>
          <div class="profileDetails">
            <div class="name">Il matrimonio di Emanuele e Viola</div>
            <div class="title">28 settembre 2024<br>
            Savona</div>
          </div>
        </div>
        <div class="infoBox">
          <div class="infoTop">
            Livello<span class="levelCircle">4</span>
          </div>
          <div class="catBox">
            <div>
              <img :src="cat.image" :alt="cat.name">
            </div>
            <div class="catRight">
              <div class="catName">{{ cat.name }}</div>
              <div class="catLevel">{{ cat.level }}</div>
            </div>
            <div v-if="showBubble" class="speech-bubble">Miao!</div>
          </div>
          <div v-if="showPetMessage" class="message">{{ sisselMessage || seriMessage }}</div>
          <div v-if="showPetButton" class="petButton" @click="petCat()">Accarezza</div>
        </div>
      </div>
      <div class="content">
        <div class="leftSection">
          <div class="box">
            <div class="showcase">
              <div class="boxTitle">Giochi in evidenza</div>
              <!-- <img src="@/assets/showcase.png" alt="Showcase"> -->
              <div class="boxBody">
                <div class="images">
                  <div class="imgRow">
                    <a href="https://store.steampowered.com/app/753640/Outer_Wilds/"><div class="image"><img src="@/assets/outerwilds.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/391540/Undertale/"><div class="image"><img src="@/assets/undertale.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/620/Portal_2/"><div class="image"><img src="@/assets/portal2.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/570/Dota_2/"><div class="image"><img src="@/assets/dota2.jpg"></div></a>
                  </div>
                  <div class="imgRow">
                    <a href="https://store.steampowered.com/app/996580/Spyro_Reignited_Trilogy/"><div class="image"><img src="@/assets/spyro.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/1967430/Ghost_Trick_Phantom_Detective/"><div class="image"><img src="@/assets/ghosttrick_showcase.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/529340/Victoria_3/"><div class="image"><img src="@/assets/victoria3.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/1158310/Crusader_Kings_III/"><div class="image"><img src="@/assets/crusaderkings3.jpg"></div></a>
                  </div>
                  <div class="imgRow">
                    <a href="https://store.steampowered.com/app/413150/Stardew_Valley/"><div class="image"><img src="@/assets/stardew.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/646570/Slay_the_Spire/"><div class="image"><img src="@/assets/slaythespire.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/1332010/Stray/"><div class="image"><img src="@/assets/stray.jpg"></div></a>
                    <a href="https://store.steampowered.com/app/2379780/Balatro/"><div class="image"><img src="@/assets/balatro.jpg"></div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box" id="dovequando">
            <div class="boxTitle">Dove e quando</div>
            <div class="boxBody">
              <div class="boxLeft">
                Cerimonia e pranzo al <a href="https://marehotel.it/">Mare Hotel</a> - Savona<br>28 settembre 2024, Ore 11.00
                <br>
                <br>
                Il matrimonio si terrà sulla spiaggia e il pranzo verrà servito al<br>Mare Hotel. Seguiranno festeggiamenti e taglio della torta.
              </div>
              <div class="mareImg"><a href="https://marehotel.it/"><img src="@/assets/mare.png" alt="Avatar"></a></div></div>
              <div class="boxFooter">Progresso per ottenere la medaglia "Matrimonio":
                <div class="progress-bar-container">
                  <div class="progress-bar"></div>
                </div>
              </div>
          </div>
          <div class="box" id="regali">
            <div class="boxTitle">Regali</div>
            <div class="boxBody">
              <div class="boxLeft">
                Se avete piacere a farci un regalo, vi invitiamo a contribuire a quello che sarà probabilmente il viaggio più bello della nostra vita (o almeno della nostra vita finora): la nostra luna di miele in Giappone e alle Maldive!
                <div class="linkSection">
                  <a href="https://www.volvertouroperator.it/liste/wedding-list">Clicca qui per aprire il sito dell'agenzia</a>
                  <br><br>
                  Dovete inserire la chiave d'accesso "Giappone" e cliccare su entra, è la seconda sezione.
                </div>
              </div>
            </div>
          </div>
          <div class="box" id="dresscode">
            <div class="boxTitle">Dress Code</div>
            <div class="boxBody">
              <div class="boxLeft">
                Non è previsto alcun tipo di dress code, potete indossare quello che più vi fa sentire a vostro agio!<br><br>
                Casomai dovesse piacervi l'idea di vestirvi "a tema", sappiate che i colori del nostro matrimonio saranno l'azzurro e l'arancione, in più potete indossare qualsiasi accessorio o indumento possa ricordare il tema del matrimonio (che sono i videogiochi, o in senso più ampio tutto ciò che è "nerd").<br>
                <br>Per il resto, sentitevi liberi di indossare <i>davvero</i> quello che vi piace, anche ciò che necessariamente non è visto di buon occhio ai matrimoni. Se volete indossare un vestito nero potete farlo, se volete venire in pantaloncini e maglietta potete farlo. Vi chiediamo solo di evitare vestiti lunghi e bianchi, probabilmente Viola si vestirà di bianco solo quel giorno e ci terrebbe a essere l'unica :P<br>
                <br>Tenete solo conto di dove si svolge il rito, certe calzature potrebbero non essere comode per camminare sulla sabbia!
              </div>
            </div>
          </div>
          <div class="box" id="domanderisposte">
            <div class="boxTitle">Domande e risposte</div>
            <div class="boxBody">
              <div class="boxLeft">
                <div class="titleQuestion">Dove posso parcheggiare?</div>
                <div class="bodyQuestion">L'hotel ha un'area parcheggio, inoltre ci sono numerosi parcheggi nei dintorni.</div>
                <div class="titleQuestion">Vengo da lontano, vorrei dormire a Savona prima e/o dopo la cerimonia. Dove posso andare?</div>
                <div class="bodyQuestion">Puoi valutare di dormire al Mare Hotel, che dà ai nostri ospiti la possibilità di alloggiare a un prezzo ridotto utilizzando il codice "weddingday" da inserire direttamente sul <a href="https://marehotel.it/">loro sito</a>.</div>
                <div class="titleQuestion">Ho delle esigenze alimentari particolari, devo farvelo presente?</div>
                <div class="bodyQuestion">Sì, ti invitiamo a contattarci tramite il form qui sotto.</div>
                <div class="titleQuestion">Perché avete messo dei videogiochi sul sito delle vostre nozze?</div>
                <div class="bodyQuestion">In parte è perché amiamo i videogiochi e sono un po' il tema del matrimonio. In parte perché i nostri videogiochi preferiti daranno il nome ai tavoli :D</div>
              </div>
            </div>
          </div>
<!--           <div class="box" id="contattaci">
            <div class="boxTitle">Contattaci</div>
            <div class="boxBody">
              <form class="contactForm">
                  <div class="formGroup">
                    <input type="text" placeholder="Inserisci il tuo nome" class="inputField" />
                  </div>
                <div class="formGroup">
                  <input type="email" placeholder="Inserisci la tua mail" class="inputField" />
                </div>
                <div class="formGroup">
                  <textarea placeholder="Inserisci il tuo messaggio" class="inputField textareaField"></textarea>
                </div>
                <div class="formGroup">
                  <button type="button" class="submitButton">Invia</button>
                </div>
              </form>
            </div>
          </div> -->
          <div class="footer">
            Creato da Viola
          </div>
        </div>
        <div class="rightSection">
          <div class="onlineSection">
            <img :src="randomGame.image" :alt="randomGame.name">
            <div class="onlineRight">
              Online<br>
              <div class="onlineSub">Giocano a: {{ randomGame.name }}</div>
            </div>
          </div>
          <div class="badgeSection">
            <div class="badgeTop">
              <div class="badgeTitle">Medaglie</div>
              <div class="badgeSub">4</div>
            </div>
            <div class="badgeContainer">
              <img src="@/assets/badges/convivenza.png" alt="Convivenza" title="Convivenza - Ottenuto il 7 febbraio 2020">
              <img src="@/assets/badges/cat.png" alt="Gatto" title="Adozione di un gatto - Ottenuto il 27 luglio 2022">
              <img src="@/assets/badges/home.png" alt="Casa" title="Acquisto di una casa - Ottenuto il 27 luglio 2023">
              <img src="@/assets/badges/proposta.png" alt="Proposta" title="Proposta di matrimonio - Ottenuto il 28 settembre 2023">
          </div>
          </div>
          <div class="sections">
            <a href="#dovequando">Dove e quando</a><br>
            <a href="#regali">Regali</a><br>
            <a href="#domanderisposte">Domande e risposte</a><br>
            <a href="#contattaci">Contattaci</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'GenericPage',

  data() {
  return {
    cats: [
      {
        name: 'Serì',
        level: 'Livello 1',
        image: require('@/assets/seri.png')
      },
      {
        name: 'Sissel',
        level: 'Livello 7',
        image: require('@/assets/sissel.png')
      }
    ],
    games: [
      "Outer Wilds",
      "Undertale",
      "Portal 2",
      "Dota 2",
      "Spyro",
      "Ghost Trick",
      "Victoria 3",
      "Crusader Kings 3",
      "Stardew Valley",
      "Slay the Spire",
      "Stray",
      "Balatro"
    ],

    gameImages: {
      "Outer Wilds": require("@/assets/icons/outerwilds.jpg"),
      "Undertale": require("@/assets/icons/undertale.jpg"),
      "Portal 2": require("@/assets/icons/portal2.jpg"),
      "Dota 2": require("@/assets/icons/dota2.jpg"),
      "Spyro": require("@/assets/icons/spyro.jpg"),
      "Ghost Trick": require("@/assets/icons/ghosttrick.jpg"),
      "Victoria 3": require("@/assets/icons/victoria3.jpg"),
      "Crusader Kings 3": require("@/assets/icons/crusaderkings3.jpg"),
      "Stardew Valley": require("@/assets/icons/stardewvalley.jpg"),
      "Slay the Spire": require("@/assets/icons/slaythespire.jpg"),
      "Stray": require("@/assets/icons/stray.jpg"),
      "Balatro": require("@/assets/icons/balatro.jpg")
    },


    cat: {},
    showBubble: false,
    showPetButton: true,
    showPetMessage: false,
    sisselPetCount: 0,
    seriPetCount: 0,
    sisselMessage: '',
    seriMessage: '',
    randomGame: {
        name: '',
        image: ''
      }
  };
},


  mounted() {
    this.randomizeCat();
    this.randomGame = this.getRandomGame();

    const startDate = moment('2023-09-28');
    const endDate = moment('2024-09-28');

    const progressPercentage = this.calculateProgress(startDate, endDate);
    this.setProgressBarWidth(progressPercentage);
  },

  methods: {
    randomizeCat() {
      const randomIndex = Math.floor(Math.random() * this.cats.length);
      this.cat = this.cats[randomIndex];
    },

    calculateProgress(startDate, endDate) {
      const currentDate = moment();
      const totalDays = endDate.diff(startDate, 'days');
      const elapsedDays = currentDate.diff(startDate, 'days');
      const progressPercentage = (elapsedDays / totalDays) * 100;
      return progressPercentage.toFixed(2); // Restituisce la percentuale con due decimali
    },

    // Imposta la larghezza della progress bar in base alla percentuale di avanzamento
    setProgressBarWidth(progressPercentage) {
      const progressBar = document.querySelector('.progress-bar');
      progressBar.style.width = progressPercentage + '%';
    },

    petCat() {
      if (this.cat.name === 'Sissel') {
        if (this.sisselPetCount === 1) {
          this.sisselMessage = "Sissel se ne è andata, è un po' timida...";
          this.showPetButton = false;
          this.showPetMessage = true;
          setTimeout(() => {
            this.showPetMessage = false;
          }, 3000);
        } else {
          this.sisselPetCount++;
        }
      }
      else if (this.cat.name === 'Serì') {
        if (this.seriPetCount === 2) {
          this.seriMessage = "Serì ti ha morso credendo che volessi giocare, -1HP";
          this.showPetMessage = true;
          setTimeout(() => {
            this.showPetMessage = false;
          }, 3000);
        } else {
          this.seriPetCount++;
        }
      }

      this.showBubble = true;
      setTimeout(() => {
        this.showBubble = false;
      }, 1000);
    },

    getRandomGame() {
      const randomIndex = Math.floor(Math.random() * this.games.length);
      const gameName = this.games[randomIndex];
      const imageName = this.gameImages[gameName];
      return { name: gameName, image: imageName };
    }
  },
};
</script>



<style scoped>
@media screen and (max-width: 600px) {
  .container {
    height: 100vh;
    /*display: flex;*/
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    font-family: 'Motiva Sans', sans-serif;
  }
}

@media not screen and (max-width: 600px) {
  .container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    font-family: 'Motiva Sans', sans-serif;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/background.jpg');
  background-size: cover;
  background-position: center;
  z-index: -1;
}
@media screen and (max-width: 600px) {
  .panel {
    width: 400px;
    height: 310%;
    margin-top: 20px;
    margin-bottom: 0;
    background-color: rgba(43, 43, 66, 0.86);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
  }
}
@media not screen and (max-width: 600px) {
  .panel {
    width: 1250px;
    height: 310%;
    margin-top: 20px;
    margin-bottom: 0;
    background-color: rgba(43, 43, 66, 0.86);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
  }
}

.name, .title {
  color: #fff;
  font-size: 1.5rem;
}

.content {
  display: flex;
  flex: 1;
}

.leftSection {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.box {
  width: 100%;
  margin-bottom: 2rem;
}

.boxTitle {
  background-color: #2D3B48;
  height: 50px;
  color: white;
  font-size: 25px;
  padding-left: 1rem;
  padding-top: 1rem;
}

.boxBody {
  background-color: #18252B;
  color: white;
  font-size: 18px;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;
  text-align: justify;
}

.mareImg {
  margin-left: auto;
}

.mareImg img {
  max-width: 80%;
  max-height: 80%;
  margin-bottom: 1rem;
  width: 150px;
}

.boxFooter {
  background-color: #100F15;
  color: white;
  padding: 1rem;
  font-size: 18px;
  display: flex;
}
.rightSection {
  width: 30%;  
  background-color: #191722;
  margin-left: 0.5rem;
  flex: 1;
  color: white;
  font-size: 18px;
  padding:1rem;
}

.profileSection {
  display: flex;
  margin-bottom: 2rem;
}

.profileLeft {
  display: flex;
}

.profilePic {
  margin-right: 1rem;
}

.profilePic img {
  width:200px;
  height: 200px;
}

.profileDetails {
}

.name {
  font-size: 28px;
}

.title {
  font-size: 20px;
}

.infoBox {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  margin-right: -1rem;
  margin-left: auto;
  color: #fff;
}

.infoTop {
  color: #fff;
  font-size: 20px;
}

.catBox {
  display: flex;
  flex-direction: row;
  background-color: #191722;
  padding: 1rem;
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: -0.5rem;
}

.progress-bar-container {
  width: 30%;
  height: 30px;
  background-color: #1A1D32;
  border-radius: 5px;
  overflow: hidden;
  margin-left: auto;
}

.progress-bar {
  height: 100%;
  background-color: #465858;
  transition: width 0.3s ease-in-out;
}

a:link { 
  text-decoration: underline; 
  color: white;
  font-weight: 700;
} 
a:visited { 
  color: white;
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}

.linkSection {
  text-align: center;
  padding: 1rem;
}

.showcase img {
  width:100%;
}

.levelCircle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid #576D61;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: white;
  margin-left: 0.5rem;
}

.catBox img {
  width: 50px;
  height: 50px;
}

.catRight {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  font-size: 20px;
}

.petButton {
  background-color: #191E24;
  padding: 1rem;
  margin-bottom: 5rem;
  margin-top: 0.5rem;
  width: 100px;
  text-align: center;
  margin-left: -0.5rem;
  cursor: pointer;
}

.onlineSection {
  display: flex;
  margin-bottom: 2rem;
  font-size: 22px;
}

.onlineSection img {
  width: 65px;
  height: 65px;
}

.onlineRight {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.onlineSub {
  color: #A7A7A7;
  font-size: 14px;
}

.badgeSection img {
  width: 65px;
  height: 65px;
  margin:6px;
  margin-bottom: 3rem;
}

.badgeTop {
  display: flex;
}

.badgeTitle {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 22px;
}

.badgeSub {
  color: #A7A7A7;
  font-size: 22px;
}

.titleQuestion {
  color: white;
  font-size: 20px;

}

.bodyQuestion {
  color: #A7A7A7;
  font-size: 18px;
  margin-bottom: 1.5rem;
}

.box {
  width: 100%;
  margin-bottom: 2rem;
}

.boxTitle {
  background-color: #2D3B48;
  height: 50px;
  color: white;
  font-size: 25px;
  padding-left: 1rem;
  padding-top: 1rem;
}

.boxBody {
  background-color: #18252B;
  color: white;
  padding: 1rem;
  font-size: 18px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formGroup {
  margin-bottom: 1rem;
  display: flex;
}

.inputField {
  width: 50%;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #5D778E;
  color: white;
}

.textareaField {
  height: 100px;
  resize: none;
  width: 100%;
  background-color: #5D778E;
  color: white;
}

.submitButton {
  background-color: #100F15;
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #100F15;
}

.sections {
  padding-bottom:0.5rem;
  font-size: 22px;
}

.speech-bubble {
  left: 50%;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  white-space: nowrap;
  margin-left: auto;
  height: 20px;
}

.image {
  width: 148px;
  height: 56px;
}


.imgRow {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.image {
  flex: 1;
  margin: 0 0.2rem;
  box-sizing: border-box;
  width: 189px;
}

.image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

</style>
